import { Trans } from "react-i18next";
import { t } from "i18next";

const Footer = () => {
  function scrollUp() {
    window.scrollTo(0, 0);
  }

  const LinkStyle = {
    fontWeight: "600",
    color: "var(--black)",
    margin: ".5em 0",
  };

  const footerLink = {
    footerCompanyLink: [
      {
        name: <Trans i18nKey="description.about" />,
        link: "/about",
      },
      {
        name: <Trans i18nKey="description.career" />,
        link: "/careers",
      },
      {
        name: <Trans i18nKey="description.internship" />,
        link: "/internships",
      },
      {
        name: "Newsroom",
        link: "/newsroom",
      },
      {
        name: <Trans i18nKey="description.terms" />,
        link: "/terms-of-service/",
        target: "_blank",
      },
      {
        name: <Trans i18nKey="description.policy" />,
        link: "/privacy/policy/",
        target: "_blank",
      },
    ],
    footerCustomerLink: [
      {
        name: "Help center",
        link: "/help",
      },
      {
        name: "FAQ",
        link: "/faq",
      },
    ],
    footerDeveloperLink: [
      {
        name: "Fleet electrification",
        link: "/products/evrecommend",
        target: "_blank",
      },
      {
        name: "Fleet management",
        link: "/products/digitalfleet",
        target: "_blank",
      },
      // {
      //   name: "PZU iFlota",
      //   link: "https://www.pzu.pl/pzuiflota",
      //   target: "_blank",
      // },
      // {
      //   name: "Lafarge iVMS",
      //   link: "https://front-beta.digitalfleet.eu/lafarge/",
      //   target: "_blank",
      // },
      // {
      //   name: "Fleet Saver",
      //   link: t("description.fleetSaverLink"),
      //   target: "_blank",
      // },
    ],
    footerEU: [
      {
        img: t("description.footer1"),
        link: "/euprojects",
      },
      {
        img: t("description.footer2"),
        link: "/euprojects",
      },
      {
        img: t("description.footer3"),
        link: "/euprojects",
      },
      {
        img: t("description.footer4"),
        link: "/euprojects",
      },
      {
        img: t("description.footer5"),
        link: "/euprojects",
      },
      {
        img: t("description.footer6"),
        link: "/euprojects",
      },
    ],
  };

  return (
    <footer
      className="section"
      style={{ backgroundColor: "var(--white)", paddingBottom: "0" }}
    >
      <div className="footer">
        <div className="footerSocialMedia">
          <p style={{ fontWeight: "bolder", margin: "1em 0" }}>
            VivaDrive Polska sp. z o.o.
          </p>
          <p>Dobra 56/66</p>
          <p style={{ marginBottom: "1em" }}> 00-312 Warszawa, Poland</p>
          <p style={{ marginBottom: "2em" }}> VAT-ID: PL7010929600</p>
          {/* <p className="firstP">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the.</p> */}
          {linkedin}
          {/* {twitter} */}
          {facebook}
          {/* {medium} */}
        </div>
        <div>
          <h4 style={{ opacity: ".4" }}>
            <Trans i18nKey="description.nav2" />
          </h4>

          {footerLink.footerCompanyLink.map((c, i) => (
            <a href={c.link} key={i + 1} target={c.target}>
              <p style={LinkStyle}>{c.name}</p>
            </a>
          ))}
        </div>
        {/* <div>
          <h4 style={{ opacity: ".4" }}>For Customers</h4>
          {footerLink.footerCustomerLink.map((c, i) => (
            <Link to={c.link} onClick={scrollUp} key={i + 1}>
              <p style={LinkStyle}>{c.name}</p>
            </Link>
          ))}
        </div> */}
        <div>
          <h4 style={{ opacity: ".4" }}>
            <Trans i18nKey="description.nav1" />
          </h4>
          {footerLink?.footerDeveloperLink.map((c, i) => (
            <a href={c.link} onClick={scrollUp} key={i + 1} target={c.target}>
              <p style={LinkStyle}>{c.name}</p>
            </a>
          ))}
        </div>
        <div>
          <h4 style={{ opacity: ".4" }}>
            <Trans i18nKey="description.nav3" />
          </h4>
          <div>
            {footerLink?.footerEU.map((c, i) => (
              <a href={c.link} onClick={scrollUp} key={i + 1} target={c.target}>
                <img src={c.img} style={{ margin: "2vh 0", width: "8em" }} />
              </a>
            ))}
          </div>
        </div>
      </div>

      <center style={{ marginTop: "2em" }}>
        <p style={{ fontSize: "12px" }}>
          {" "}
          Copyright © 2023 VivaDrive Polska sp.z.o.o. All rights reserved
        </p>
      </center>
    </footer>
  );
};

export default Footer;

const linkedin = (
  <a
    target="_blank"
    rel="noreferrer"
    href="https://www.linkedin.com/company/vivadrive/"
  >
    <svg
      className="iconSocial"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Linkedin title</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 28.5H25.3846V20.0951C25.3846 17.7894 24.4073 16.5033 22.6546 16.5033C20.7473 16.5033 19.6154 17.8434 19.6154 20.0951V28.5H15V12.8886H19.6154V14.6443C19.6154 14.6443 21.0635 12 24.3265 12C27.5908 12 30 14.0727 30 18.3622C30 22.6505 30 28.5 30 28.5ZM8.25046 9C7.00727 9 6 7.99228 6 6.74954C6 5.50772 7.00727 4.5 8.25046 4.5C9.49273 4.5 10.5 5.50772 10.5 6.74954C10.5009 7.99228 9.49273 9 8.25046 9ZM6 28.5H10.5V13.5H6V28.5Z"
        fill="var(--primaryGreen)"
      />
    </svg>
  </a>
);

// const twitter = (
//   <a target="_blank" rel="noreferrer" href="https://twitter.com/vivadriveio">
//     <svg
//       className="iconSocial"
//       width="36"
//       height="36"
//       viewBox="0 0 36 36"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <title>Twitter icon</title>
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M13.548 28.5C22.6044 28.5 27.558 20.4198 27.558 13.4134C27.558 13.1834 27.558 12.9547 27.5436 12.7273C28.5072 11.9778 29.3388 11.0474 30 9.98259C29.1024 10.4116 28.1484 10.6933 27.1728 10.8174C28.2 10.1545 28.9692 9.11293 29.3376 7.88404C28.3704 8.50172 27.3132 8.93719 26.2104 9.17108C24.3456 7.03635 21.2268 6.93297 19.2432 8.94107C17.9652 10.2359 17.4216 12.1664 17.8188 14.0078C13.86 13.7933 10.1712 11.7801 7.6704 8.46812C6.3636 10.891 7.032 13.9897 9.1956 15.5456C8.412 15.521 7.6452 15.2936 6.96 14.8827V14.9499C6.9612 17.4736 8.6136 19.6471 10.9104 20.1471C10.1856 20.3604 9.4248 20.3914 8.688 20.2376C9.3324 22.3982 11.1816 23.8778 13.2876 23.9204C11.544 25.3961 9.39 26.1973 7.1724 26.1947C6.7812 26.1934 6.39 26.1688 6 26.1185C8.2524 27.6743 10.872 28.5 13.548 28.4961"
//         fill="var(--primaryGreen)"
//       />
//     </svg>
//   </a>
// );

const facebook = (
  <a
    target="_blank"
    rel="noreferrer"
    href="https://www.facebook.com/vivadriveio/"
  >
    <svg
      className="iconSocial"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Facebook Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1855 28.5V18.375H23.4644L24 13.875H20.1855V11.6835C20.1855 10.5248 20.2171 9.375 21.9443 9.375H23.6936V6.1575C23.6936 6.10912 22.191 6 20.6708 6C17.496 6 15.5081 7.86413 15.5081 11.2875V13.875H12V18.375H15.5081V28.5H20.1855Z"
        fill="var(--primaryGreen)"
      />
    </svg>
  </a>
);
